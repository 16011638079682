import React from 'react';

import {Content, ContentSection} from 'components';
import {Button, Heading} from 'components/ultra';
import Sidebar from 'containers/sidebar';

export function NotFound() {
  return (
    <>
      <Sidebar />
      <Content fullHeight verticalCenter>
        <ContentSection bottomSpacing>
          <Heading element="h1" gradient={true}>
            Sorry,
          </Heading>
          <Heading>we couldn’t find the page you were looking for.</Heading>
          <p>Let's find a better place for you to go.</p>
          <Button onClick={() => history.back()} severity="inverse">
            Go back
          </Button>{' '}
          <Button to="/">Go home</Button>
        </ContentSection>
      </Content>
    </>
  );
}
